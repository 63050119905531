import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, NavDropdown, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import 'assets/scss/section/_header.scss';
import profile_image_icon from 'assets/images/Profile.jpg';
import { logoutSuccess, loggedUser } from 'store';
import { LogoUrlFront } from 'common';
import { LOGIN, EDIT_PROFILE, NOTIFICATIONS } from 'config';
import { decryptData } from 'helpers/cryptoHelper';
import { useNotificationCount } from 'hooks';
import { useNotification } from '../../../context/NotificationContext';

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notificationCount } = useNotification();
  const { updateNotificationCount } = useNotification();

  // Hook call to fetch notification count
  const { data: notificationData, error: notificationError, refetch } = useNotificationCount();

  useEffect(() => {
    refetch();
    if (notificationData) {
      updateNotificationCount(notificationData.data);
    }
    if (notificationError) {
      window.scrollTo(0, 0);
    }
  }, [notificationData, notificationError]);
  const [isNotificationListVisible, setIsNotificationListVisible] = useState(false);

  const handleEditProfile = () => {
    navigate(EDIT_PROFILE);
  };

  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate(LOGIN);
  };

  const checkLoggedInUser = useSelector(loggedUser);

  const handleBellClick = () => {
    navigate(NOTIFICATIONS);
    setIsNotificationListVisible(!isNotificationListVisible);
  };

  const UserMenu = (
    <svg width="9" height="21" viewBox="0 0 9 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="4.44691" cy="4.49619" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="10.4886" rx="1.44691" ry="1.49619" fill="#9592A6" />
      <ellipse cx="4.44691" cy="16.4809" rx="1.44691" ry="1.49619" fill="#9592A6" />
    </svg>
  );

  return (
    <>
      <Navbar className="sticky-top navbar-section">
        <Container fluid className="container-section">
          <Navbar.Brand className="dashboard-logo">
            <LogoUrlFront />
          </Navbar.Brand>

          <div className="header-setting-menu">
            <div className="dashboard-toggle-btn me-2">
              <FontAwesomeIcon icon={faBars} onClick={props.toggleClass} />
            </div>
            <div className="d-flex align-itrms-center">
              <div
                className="notification-icon me-2"
                onClick={handleBellClick}
                style={{ position: 'relative' }}>
                <FontAwesomeIcon icon={faBell} className="me-2" />
                {<span className="notification-count"></span>}
                <span className={`notification-counter ${!notificationCount ? 'd-none' : ''}`}>
                  {notificationCount ? (notificationCount > 10 ? '+9' : notificationCount) : null}
                </span>
              </div>
              <span className="desktop-login-name">
                {decryptData(checkLoggedInUser.user.first_name) +
                  ' ' +
                  decryptData(checkLoggedInUser.user.last_name)}
              </span>
            </div>
            <div className="profile-img">
              <img
                src={
                  checkLoggedInUser.user.profile_image
                    ? checkLoggedInUser.user.profile_image
                    : profile_image_icon
                }
                width="40px"
                height="40px"
                alt="profile_img"
              />
            </div>
            <NavDropdown title={UserMenu}>
              <div className="mobile-login-name">
                <span>
                  {decryptData(checkLoggedInUser.user.first_name) +
                    ' ' +
                    decryptData(checkLoggedInUser.user.last_name)}
                </span>
                <NavDropdown.Divider className="separator" />
              </div>
              <NavDropdown.Item className="setting-items" onClick={handleEditProfile}>
                {props.t('page.header_edit_profile')}
              </NavDropdown.Item>
              <NavDropdown.Item className="setting-items" onClick={handleLogout}>
                {props.t('page.header_logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

Header.propTypes = {
  toggleClass: PropTypes.any.isRequired,
  t: PropTypes.func,
};

export { Header };

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Nav, Row, Form, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import validationSchema from './HomeFooterSettingValidation';
import { useFormik } from 'formik';
import { useStoreSettingData, useGetHomeSettingData } from 'hooks';
import { addSetting } from 'store/features/settingSlice';
import { TNButton } from 'common/components/TNButton';
import { confirmAlert } from 'react-confirm-alert';
import { AlertCommon } from 'common/components/AlertCommon';
import 'assets/scss/page/_generalsetting.scss';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
const languages = ['en', 'sp']; // Add more languages here if needed

const HomeFooterSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let homeFooterImageRef = useRef();
  const [homeSettingData, setHomeSettingData] = useState(null);
  useEffect(() => {
    // Fetch card list on component mount
    dispatch(showLoader());
  }, []);
  useGetHomeSettingData(
    {
      tag: 'home_setting_footer',
    },
    ({ data: setting }) => {
      const dataStore = {
        home_setting_footer_call_icon: setting.home_setting_footer_call_icon,
        home_setting_footer_call_icon_text: setting.home_setting_footer_call_icon_text,
        home_setting_footer_mail_icon: setting.home_setting_footer_mail_icon,
        home_setting_footer_mail_icon_text: setting.home_setting_footer_mail_icon_text,
        home_setting_footer_location_one_icon: setting.home_setting_footer_location_one_icon,
        home_setting_footer_location_two_icon: setting.home_setting_footer_location_two_icon,
        home_setting_footer_first_icon: setting.home_setting_footer_first_icon,
        home_setting_footer_first_icon_link: setting.home_setting_footer_first_icon_link,
        home_setting_footer_second_icon: setting.home_setting_footer_second_icon,
        home_setting_footer_second_icon_link: setting.home_setting_footer_second_icon_link,
        home_setting_footer_third_icon: setting.home_setting_footer_third_icon,
        home_setting_footer_third_icon_link: setting.home_setting_footer_third_icon_link,
        home_setting_footer_forth_icon: setting.home_setting_footer_forth_icon,
        home_setting_footer_forth_icon_link: setting.home_setting_footer_forth_icon_link,
        home_setting_footer_fifth_icon: setting.home_setting_footer_fifth_icon,
        home_setting_footer_fifth_icon_link: setting.home_setting_footer_fifth_icon_link,
        home_setting_footer_six_icon: setting.home_setting_footer_six_icon,
        home_setting_footer_six_icon_link: setting.home_setting_footer_six_icon_link,
        home_setting_footer_logo_image: setting.home_setting_footer_logo_image,
      };
      languages.forEach((lang) => {
        dataStore[`home_setting_footer_location_one_icon_${lang}_text`] =
          setting[`home_setting_footer_location_one_icon_text`]?.[lang] || '';
        dataStore[`home_setting_footer_location_two_icon_${lang}_text`] =
          setting[`home_setting_footer_location_two_icon_text`]?.[lang] || '';
      });
      setHomeSettingData(dataStore);
      dispatch(hideLoader());
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        dispatch(hideLoader());
        navigate('/dashboard');
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
    isLoading,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    const dataStore = {
      home_setting_footer_call_icon: response.data.home_setting_footer_call_icon,
      home_setting_footer_call_icon_text: response.data.home_setting_footer_call_icon_text,
      home_setting_footer_mail_icon: response.data.home_setting_footer_mail_icon,
      home_setting_footer_mail_icon_text: response.data.home_setting_footer_mail_icon_text,
      home_setting_footer_location_one_icon: response.data.home_setting_footer_location_one_icon,
      home_setting_footer_location_two_icon: response.data.home_setting_footer_location_two_icon,
      home_setting_footer_first_icon: response.data.home_setting_footer_first_icon,
      home_setting_footer_first_icon_link: response.data.home_setting_footer_first_icon_link,
      home_setting_footer_second_icon: response.data.home_setting_footer_second_icon,
      home_setting_footer_second_icon_link: response.data.home_setting_footer_second_icon_link,
      home_setting_footer_third_icon: response.data.home_setting_footer_third_icon,
      home_setting_footer_third_icon_link: response.data.home_setting_footer_third_icon_link,
      home_setting_footer_forth_icon: response.data.home_setting_footer_forth_icon,
      home_setting_footer_forth_icon_link: response.data.home_setting_footer_forth_icon_link,
      home_setting_footer_fifth_icon: response.data.home_setting_footer_fifth_icon,
      home_setting_footer_fifth_icon_link: response.data.home_setting_footer_fifth_icon_link,
      home_setting_footer_six_icon: response.data.home_setting_footer_six_icon,
      home_setting_footer_six_icon_link: response.data.home_setting_footer_six_icon_link,
      home_setting_footer_logo_image: response.data.home_setting_footer_logo_image,
    };
    languages.forEach((lang) => {
      dataStore[`home_setting_footer_location_one_icon_${lang}_text`] =
        response.data?.home_setting_footer_location_one_icon_text?.[lang] || '';
      dataStore[`home_setting_footer_location_two_icon_${lang}_text`] =
        response.data?.home_setting_footer_location_two_icon_text?.[lang] || '';
    });
    dispatch(addSetting(dataStore));
    setTimeout(function () {
      window.location.reload();
    }, 1500);
  });
  /**
   * !This block is for image preview
   */
  const imagePreviewFromik = (file) => {
    return typeof file === 'string' ? file : URL.createObjectURL(file);
  };
  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <div className="alert-box">
            <FontAwesomeIcon className="alert-close" icon={faClose} onClick={onClose} />
            <div className="alert-popup">
              <h2 dangerouslySetInnerHTML={{ __html: t('page.reset_alert_popup_message') }}></h2>
              <Button
                className="table-delete-button"
                onClick={() => {
                  onClose();
                  navigate('/dashboard', { replace: true });
                }}>
                {t('page.alert_popup_yes_button')}
              </Button>
              <Button className="table-primary-button" onClick={onClose}>
                {t('page.alert_popup_no_button')}
              </Button>
            </div>
          </div>
        ),
      });
    } else {
      navigate('/dashboard', { replace: true });
    }
  };

  const formik = useFormik({
    initialValues: {
      home_setting_footer_call_icon: homeSettingData?.home_setting_footer_call_icon || '',
      home_setting_footer_call_icon_text: homeSettingData?.home_setting_footer_call_icon_text || '',
      home_setting_footer_mail_icon: homeSettingData?.home_setting_footer_mail_icon,
      home_setting_footer_mail_icon_text: homeSettingData?.home_setting_footer_mail_icon_text,
      home_setting_footer_location_one_icon: homeSettingData?.home_setting_footer_location_one_icon,
      home_setting_footer_location_two_icon: homeSettingData?.home_setting_footer_location_two_icon,
      home_setting_footer_first_icon: homeSettingData?.home_setting_footer_first_icon || '',
      home_setting_footer_first_icon_link:
        homeSettingData?.home_setting_footer_first_icon_link || '',
      home_setting_footer_second_icon: homeSettingData?.home_setting_footer_second_icon || '',
      home_setting_footer_second_icon_link:
        homeSettingData?.home_setting_footer_second_icon_link || '',
      home_setting_footer_third_icon: homeSettingData?.home_setting_footer_third_icon || '',
      home_setting_footer_third_icon_link:
        homeSettingData?.home_setting_footer_third_icon_link || '',
      home_setting_footer_forth_icon: homeSettingData?.home_setting_footer_forth_icon || '',
      home_setting_footer_forth_icon_link:
        homeSettingData?.home_setting_footer_forth_icon_link || '',
      home_setting_footer_fifth_icon: homeSettingData?.home_setting_footer_fifth_icon,
      home_setting_footer_fifth_icon_link: homeSettingData?.home_setting_footer_fifth_icon_link,
      home_setting_footer_six_icon: homeSettingData?.home_setting_footer_six_icon,
      home_setting_footer_six_icon_link: homeSettingData?.home_setting_footer_six_icon_link,
      home_setting_footer_logo_image: homeSettingData?.home_setting_footer_logo_image || '',
      ...languages.reduce((acc, lang) => {
        acc[`home_setting_footer_location_one_icon_${lang}_text`] =
          homeSettingData?.[`home_setting_footer_location_one_icon_${lang}_text`] || '';
        acc[`home_setting_footer_location_two_icon_${lang}_txt`] =
          homeSettingData?.[`home_setting_footer_location_two_icon_${lang}_text`] || '';
        return acc;
      }, {}),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      console.log('here submit', values);
      // Format the button text to be language-specific
      const transformedButtonText = {};

      // Initialize the button text key in the object
      transformedButtonText['home_setting_footer_location_one_icon_text'] = {};
      transformedButtonText['home_setting_footer_location_two_icon_text'] = {};
      languages.forEach((lang) => {
        transformedButtonText[`home_setting_footer_location_one_icon_text`][lang] =
          values[`home_setting_footer_location_one_icon_${lang}_text`] || '';
        transformedButtonText[`home_setting_footer_location_two_icon_text`][lang] =
          values[`home_setting_footer_location_two_icon_${lang}_text`] || '';
      });
      const updatedValues = {
        ...values,
        ...transformedButtonText,
        home_setting_footer_logo_image:
          values.home_setting_footer_logo_image instanceof File
            ? values.home_setting_footer_logo_image
            : undefined,
      };
      // Remove fields that are not file objects (those set to undefined)
      Object.keys(updatedValues).forEach((key) => {
        if (updatedValues[key] === undefined) {
          delete updatedValues[key];
        }
      });
      console.log('here data ', updatedValues);
      doStoreSettingData(updatedValues);
    },
  });

  useEffect(() => {
    if (homeSettingData) {
      const initialValues = {
        home_setting_footer_call_icon: homeSettingData?.home_setting_footer_call_icon,
        home_setting_footer_call_icon_text: homeSettingData?.home_setting_footer_call_icon_text,
        home_setting_footer_mail_icon: homeSettingData?.home_setting_footer_mail_icon,
        home_setting_footer_mail_icon_text: homeSettingData?.home_setting_footer_mail_icon_text,
        home_setting_footer_location_one_icon:
          homeSettingData?.home_setting_footer_location_one_icon,
        home_setting_footer_location_two_icon:
          homeSettingData?.home_setting_footer_location_two_icon,
        home_setting_footer_first_icon: homeSettingData?.home_setting_footer_first_icon,
        home_setting_footer_first_icon_link: homeSettingData?.home_setting_footer_first_icon_link,
        home_setting_footer_second_icon: homeSettingData?.home_setting_footer_second_icon,
        home_setting_footer_second_icon_link: homeSettingData?.home_setting_footer_second_icon_link,
        home_setting_footer_third_icon: homeSettingData?.home_setting_footer_third_icon,
        home_setting_footer_third_icon_link: homeSettingData?.home_setting_footer_third_icon_link,
        home_setting_footer_forth_icon: homeSettingData?.home_setting_footer_forth_icon,
        home_setting_footer_forth_icon_link: homeSettingData?.home_setting_footer_forth_icon_link,
        home_setting_footer_fifth_icon: homeSettingData?.home_setting_footer_fifth_icon,
        home_setting_footer_fifth_icon_link: homeSettingData?.home_setting_footer_fifth_icon_link,
        home_setting_footer_six_icon: homeSettingData?.home_setting_footer_six_icon,
        home_setting_footer_six_icon_link: homeSettingData?.home_setting_footer_six_icon_link,
        home_setting_footer_logo_image: homeSettingData?.home_setting_footer_logo_image,
      };
      console.log(homeSettingData);
      // Loop through each language and dynamically set form values
      languages.forEach((lang) => {
        initialValues[`home_setting_footer_location_one_icon_${lang}_text`] =
          homeSettingData?.[`home_setting_footer_location_one_icon_${lang}_text`] || '';
        initialValues[`home_setting_footer_location_two_icon_${lang}_text`] =
          homeSettingData?.[`home_setting_footer_location_two_icon_${lang}_text`] || '';
      });

      // Set the values in Formik
      formik.setValues(initialValues);
    }
  }, [homeSettingData]);

  return (
    <>
      <Nav className="tab-navigation">
        <Nav.Item>
          <NavLink to="/setting/home/home-banner">
            {t('page.general_setting_tab_home_banner_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-motorcycle-lineup">
            {t('page.general_setting_tab_home_motorcycle_lineup_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-why-choose-us">
            {t('page.general_setting_tab_home_why_choose_us_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-footer">
            {t('page.general_setting_tab_home_footer_section')}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/setting/home/home-seo">
            {t('page.general_setting_tab_home_seo_info_section')}
          </NavLink>
        </Nav.Item>
      </Nav>
      <AlertCommon key={Math.random()} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_logo_image')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={homeFooterImageRef}
                      name="home_setting_footer_logo_image"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        const allowedFileTypes = [
                          'image/jpeg',
                          'image/jpg',
                          'image/png',
                          'image/svg+xml',
                        ];
                        if (file && allowedFileTypes.includes(file.type)) {
                          formik.setFieldValue(
                            'home_setting_footer_logo_image',
                            event.currentTarget.files[0]
                          );
                        } else {
                          toast.error(t('page.invalid_upload_format_of_image'));
                          event.target.value = '';
                        }
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => homeFooterImageRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.home_setting_footer_logo_image && (
                        <div>
                          <img
                            src={imagePreviewFromik(formik.values.home_setting_footer_logo_image)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_setting_footer_logo_image', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_setting_footer_logo_image &&
                    formik.errors.home_setting_footer_logo_image ? (
                      <div>{t(formik.errors.home_setting_footer_logo_image)}</div>
                    ) : null}
                  </div>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_call_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_call_icon &&
                        formik.errors.home_setting_footer_call_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_call_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_call_icon"
                      placeholder={t('page.home_setting_footer_call_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_call_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_call_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_call_icon &&
                        formik.errors.home_setting_footer_call_icon && (
                          <div>{t(formik.errors.home_setting_footer_call_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_call_icon_text_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_call_icon_text &&
                        formik.errors.home_setting_footer_call_icon_text
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_call_icon_text
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_call_icon_text"
                      placeholder={t('page.home_setting_footer_call_icon_text_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_call_icon_text',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_call_icon_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_call_icon_text &&
                        formik.errors.home_setting_footer_call_icon_text && (
                          <div>{t(formik.errors.home_setting_footer_call_icon_text)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_mail_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_mail_icon &&
                        formik.errors.home_setting_footer_mail_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_mail_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_mail_icon"
                      placeholder={t('page.home_setting_footer_mail_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_mail_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_mail_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_mail_icon &&
                        formik.errors.home_setting_footer_mail_icon && (
                          <div>{t(formik.errors.home_setting_footer_mail_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_mail_icon_text_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_mail_icon_text &&
                        formik.errors.home_setting_footer_mail_icon_text
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_mail_icon_text
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_mail_icon_text"
                      placeholder={t('page.home_setting_footer_mail_icon_text_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_mail_icon_text',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_mail_icon_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_mail_icon_text &&
                        formik.errors.home_setting_footer_mail_icon_text && (
                          <div>{t(formik.errors.home_setting_footer_mail_icon_text)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_location_one_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_location_one_icon &&
                        formik.errors.home_setting_footer_location_one_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_location_one_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_location_one_icon"
                      placeholder={t('page.home_setting_footer_location_one_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_location_one_icon',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_location_one_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_location_one_icon &&
                        formik.errors.home_setting_footer_location_one_icon && (
                          <div>{t(formik.errors.home_setting_footer_location_one_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  {languages.map((lang) => (
                    <>
                      <Form.Group key={lang}>
                        <Form.Label className="field-label field-label-top">
                          {t(`page.home_setting_footer_location_one_icon_text_label`)} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched[`home_setting_footer_location_one_icon_${lang}_text`] &&
                            formik.errors[`home_setting_footer_location_one_icon_${lang}_text`]
                              ? 'form-field-error'
                              : formik.touched[`home_setting_footer_location_one_icon_${lang}_text`]
                              ? 'form-field-success'
                              : ''
                          }
                          type="text"
                          name={`home_setting_footer_location_one_icon_${lang}_text`}
                          placeholder={`${t(
                            'page.home_setting_footer_location_one_icon_text_placeholder'
                          )} [${lang.toUpperCase()}]`}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `home_setting_footer_location_one_icon_${lang}_text`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values[`home_setting_footer_location_one_icon_${lang}_text`]
                          }
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`home_setting_footer_location_one_icon_${lang}_text`] &&
                            formik.errors[`home_setting_footer_location_one_icon_${lang}_text`] && (
                              <div>
                                {t(
                                  formik.errors[
                                    `home_setting_footer_location_one_icon_${lang}_text`
                                  ]
                                )}
                              </div>
                            )}
                        </div>
                      </Form.Group>

                      <Form.Group key={lang}>
                        <Form.Label className="field-label field-label-top">
                          {t(`page.home_setting_footer_location_two_icon_text_label`)} [
                          {lang.toUpperCase()}]
                        </Form.Label>
                        <Form.Control
                          className={
                            formik.touched[`home_setting_footer_location_two_icon_${lang}_text`] &&
                            formik.errors[`home_setting_footer_location_two_icon_${lang}_text`]
                              ? 'form-field-error'
                              : formik.touched[`home_setting_footer_location_two_icon_${lang}_text`]
                              ? 'form-field-success'
                              : ''
                          }
                          type="text"
                          name={`home_setting_footer_location_two_icon_${lang}_text`}
                          placeholder={`${t(
                            'page.home_setting_footer_location_two_icon_text_placeholder'
                          )} [${lang.toUpperCase()}]`}
                          onChange={(e) => {
                            const value = e.target.value;
                            formik.setFieldValue(
                              `home_setting_footer_location_two_icon_${lang}_text`,
                              value.trimStart()
                            );
                          }}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values[`home_setting_footer_location_two_icon_${lang}_text`]
                          }
                        />
                        <div className="form-field-error-text">
                          {formik.touched[`home_setting_footer_location_two_icon_${lang}_text`] &&
                            formik.errors[`home_setting_footer_location_two_icon_${lang}_text`] && (
                              <div>
                                {t(
                                  formik.errors[
                                    `home_setting_footer_location_two_icon_${lang}_text`
                                  ]
                                )}
                              </div>
                            )}
                        </div>
                      </Form.Group>
                    </>
                  ))}

                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_location_two_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_location_two_icon &&
                        formik.errors.home_setting_footer_location_two_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_location_two_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_location_two_icon"
                      placeholder={t('page.home_setting_footer_location_two_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_location_two_icon',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_location_two_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_location_two_icon &&
                        formik.errors.home_setting_footer_location_two_icon && (
                          <div>{t(formik.errors.home_setting_footer_location_two_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_location_two_icon_text_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_location_two_icon_text &&
                        formik.errors.home_setting_footer_location_two_icon_text
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_location_two_icon_text
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_location_two_icon_text"
                      placeholder={t('page.home_setting_footer_location_two_icon_text_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_location_two_icon_text',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_location_two_icon_text}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_location_two_icon_text &&
                        formik.errors.home_setting_footer_location_two_icon_text && (
                          <div>{t(formik.errors.home_setting_footer_location_two_icon_text)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_first_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_first_icon &&
                        formik.errors.home_setting_footer_first_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_first_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_first_icon"
                      placeholder={t('page.home_setting_footer_first_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_first_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_first_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_first_icon &&
                        formik.errors.home_setting_footer_first_icon && (
                          <div>{t(formik.errors.home_setting_footer_first_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_first_icon_link_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_first_icon_link &&
                        formik.errors.home_setting_footer_first_icon_link
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_first_icon_link
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_first_icon_link"
                      placeholder={t('page.home_setting_footer_first_icon_link_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_first_icon_link',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_first_icon_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_first_icon_link &&
                        formik.errors.home_setting_footer_first_icon_link && (
                          <div>{t(formik.errors.home_setting_footer_first_icon_link)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_second_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_second_icon &&
                        formik.errors.home_setting_footer_second_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_second_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_second_icon"
                      placeholder={t('page.home_setting_footer_second_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_second_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_second_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_second_icon &&
                        formik.errors.home_setting_footer_second_icon && (
                          <div>{t(formik.errors.home_setting_footer_second_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_second_icon_link_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_second_icon_link &&
                        formik.errors.home_setting_footer_second_icon_link
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_second_icon_link
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_second_icon_link"
                      placeholder={t('page.home_setting_footer_second_icon_link_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_second_icon_link',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_second_icon_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_second_icon_link &&
                        formik.errors.home_setting_footer_second_icon_link && (
                          <div>{t(formik.errors.home_setting_footer_second_icon_link)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_third_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_third_icon &&
                        formik.errors.home_setting_footer_third_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_third_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_third_icon"
                      placeholder={t('page.home_setting_footer_third_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_third_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_third_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_third_icon &&
                        formik.errors.home_setting_footer_third_icon && (
                          <div>{t(formik.errors.home_setting_footer_third_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_third_icon_link_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_third_icon_link &&
                        formik.errors.home_setting_footer_third_icon_link
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_third_icon_link
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_third_icon_link"
                      placeholder={t('page.home_setting_footer_third_icon_link_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_third_icon_link',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_third_icon_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_third_icon_link &&
                        formik.errors.home_setting_footer_third_icon_link && (
                          <div>{t(formik.errors.home_setting_footer_third_icon_link)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_forth_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_forth_icon &&
                        formik.errors.home_setting_footer_forth_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_forth_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_forth_icon"
                      placeholder={t('page.home_setting_footer_forth_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_forth_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_forth_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_forth_icon &&
                        formik.errors.home_setting_footer_forth_icon && (
                          <div>{t(formik.errors.home_setting_footer_forth_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_forth_icon_link_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_forth_icon_link &&
                        formik.errors.home_setting_footer_forth_icon_link
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_forth_icon_link
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_forth_icon_link"
                      placeholder={t('page.home_setting_footer_forth_icon_link_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_forth_icon_link',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_forth_icon_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_forth_icon_link &&
                        formik.errors.home_setting_footer_forth_icon_link && (
                          <div>{t(formik.errors.home_setting_footer_forth_icon_link)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_fifth_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_fifth_icon &&
                        formik.errors.home_setting_footer_fifth_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_fifth_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_fifth_icon"
                      placeholder={t('page.home_setting_footer_fifth_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_fifth_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_fifth_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_fifth_icon &&
                        formik.errors.home_setting_footer_fifth_icon && (
                          <div>{t(formik.errors.home_setting_footer_fifth_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_fifth_icon_link_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_fifth_icon_link &&
                        formik.errors.home_setting_footer_fifth_icon_link
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_fifth_icon_link
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_fifth_icon_link"
                      placeholder={t('page.home_setting_footer_fifth_icon_link_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_fifth_icon_link',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_fifth_icon_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_fifth_icon_link &&
                        formik.errors.home_setting_footer_fifth_icon_link && (
                          <div>{t(formik.errors.home_setting_footer_fifth_icon_link)}</div>
                        )}
                    </div>
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_six_icon_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_six_icon &&
                        formik.errors.home_setting_footer_six_icon
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_six_icon
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_six_icon"
                      placeholder={t('page.home_setting_footer_six_icon_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue('home_setting_footer_six_icon', value.trimStart());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_six_icon}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_six_icon &&
                        formik.errors.home_setting_footer_six_icon && (
                          <div>{t(formik.errors.home_setting_footer_six_icon)}</div>
                        )}
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_setting_footer_six_icon_link_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        formik.touched.home_setting_footer_six_icon_link &&
                        formik.errors.home_setting_footer_six_icon_link
                          ? 'form-field-error'
                          : formik.touched.home_setting_footer_six_icon_link
                          ? 'form-field-success'
                          : ''
                      }
                      type="text"
                      name="home_setting_footer_six_icon_link"
                      placeholder={t('page.home_setting_footer_six_icon_link_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_setting_footer_six_icon_link',
                          value.trimStart()
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_setting_footer_six_icon_link}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_setting_footer_six_icon_link &&
                        formik.errors.home_setting_footer_six_icon_link && (
                          <div>{t(formik.errors.home_setting_footer_six_icon_link)}</div>
                        )}
                    </div>
                  </Form.Group>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton type="submit" isdirtyform={formik.dirty ? 1 : 0} loading={isLoading}>
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};

HomeFooterSetting.propTypes = {
  t: PropTypes.func,
};

export { HomeFooterSetting };

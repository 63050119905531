import React from 'react';
import { Link } from 'react-router-dom';

import {
  TRANSLATIONS,
  LIST,
  FAQ,
  EMAIL_TEMPLATE,
  CMS,
  FAQ_CATEGORY,
  SERVICE,
  FEATURE,
  REVIEW,
  OUR_TEAM,
} from 'config';

const SidebarMastersLinks = (props) => {
  const { t } = props;
  const cmsClass = location.pathname.match(/^\/cms/) ? 'active' : '';
  const translationsClass = location.pathname.match(/^\/translations/) ? 'active' : '';
  const emailTemplateClass = location.pathname.match(/^\/email-template/) ? 'active' : '';
  const faqCategoryClass = location.pathname.startsWith('/faq-category/') ? 'active' : '';
  const faqClass = location.pathname.startsWith('/faq/') ? 'active' : '';
  const serviceClass = location.pathname.startsWith('/service/') ? 'active' : '';
  const featureClass = location.pathname.startsWith('/feature/') ? 'active' : '';
  const reviewClass = location.pathname.startsWith('/review/') ? 'active' : '';
  const ourTeamClass = location.pathname.startsWith('/our-team/') ? 'active' : '';
  return (
    <>
      <ul className={`submenu collapse ${props.master ? 'show' : ''}`}>
        <li>
          <Link
            className={cmsClass}
            to={CMS + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_cms')}
          </Link>
        </li>
        <li>
          <Link
            className={translationsClass}
            to={TRANSLATIONS + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_translations')}
          </Link>
        </li>
        <li>
          <Link
            className={emailTemplateClass}
            to={EMAIL_TEMPLATE + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_email_templates')}
          </Link>
        </li>
        <li>
          <Link
            className={faqCategoryClass}
            to={FAQ_CATEGORY + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_faq_category')}
          </Link>
        </li>
        <li>
          <Link
            className={faqClass}
            to={FAQ + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_faq')}
          </Link>
        </li>
        <li>
          <Link
            className={serviceClass}
            to={SERVICE + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_service')}
          </Link>
        </li>
        <li>
          <Link
            className={featureClass}
            to={FEATURE + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_feature')}
          </Link>
        </li>
        <li>
          <Link
            className={reviewClass}
            to={REVIEW + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_review')}
          </Link>
        </li>
        <li>
          <Link
            className={ourTeamClass}
            to={OUR_TEAM + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_our_team')}
          </Link>
        </li>
      </ul>
    </>
  );
};

export { SidebarMastersLinks };

import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { settingData } from 'store/features/settingSlice';
import { SettingService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetSettingData = (onSuccess, onError) => {
  const getSettingData = useSelector(settingData);
  return useQuery(
    'setting_data',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError,
      enabled: getSettingData.setting_get,
    }
  );
};
const useStoreSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.storeSettingData, {
    onSuccess,
    onError,
  });
};

const useGetSettingDataAlways = (onSuccess, onError) => {
  return useQuery(
    'setting_data_all',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetAllSettingData = (onSuccess, onError) => {
  return useQuery(
    'all_setting_data',
    () => {
      return SettingService.getSettingData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetHomeSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['home_setting_data', params],
    () => {
      return SettingService.getHomeSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useDeleteHomeSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.deleteSettingData, {
    onSuccess,
    onError,
  });
};
const useGetAboutSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['about_setting_data', params],
    () => {
      return SettingService.getAboutSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetContactSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['contact_setting_data', params],
    () => {
      return SettingService.getContactSettingData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useDeleteOfficeSettingData = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.deleteOfficeSettingData, {
    onSuccess,
    onError,
  });
};
export {
  useGetSettingData,
  useStoreSettingData,
  useGetSettingDataAlways,
  useGetAllSettingData,
  useGetHomeSettingData,
  useDeleteHomeSettingData,
  useGetAboutSettingData,
  useGetContactSettingData,
  useDeleteOfficeSettingData,
};
